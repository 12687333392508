.header {
    color: $color__white--70;
    height: 2.5rem;
    background: $color__deep-blue;
    box-shadow: inset 0px -4px 10px $color__black--06;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 calc(var(--space) + var(--padding));
    position: relative;
    width: 100%;

    @media print {
        display: none;
    }

    &__primary-nav {
        display: flex;
        height: 100%;
    }

    &__utils-nav {
        display: flex;
        height: 100%;

        @include smaller-than-or-eq(mobile) {
            display: none;
        }
    }

    &__hamburger {
        @include icon-inherits-color;
        display: none;
        color: $color__white--70;
        align-items: center;
        margin-left: 1.25rem;

        &:hover, &:focus {
            color: $color__white;
        }

        @include smaller-than-or-eq(mobile) {
            display: flex;
        }
    }

    &__nav-item {
        @include font-size(0.875);
        @include icon-inherits-color;
        @include text--link-inverted;
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 4px;
        border-top: 4px solid transparent;
        margin-left: 1.25rem;

        &--active {
            border-top-color: $color__white;
            color: $color__white;
            pointer-events: none;

            &.header__nav-dropdown-trigger {
                pointer-events: all;
            }
        }

        .header__nav-dropdown-content & {
            margin-left: 1rem;
            border-top-color: transparent;
        }

        &--help {
            margin-left: auto;
        }
    }

    &__nav-dropdown {
        position: relative;
        pointer-events: auto;

        & + & {
            margin-left: .25rem;
        }
    }

    &__nav-dropdown-trigger {
        @include text--link-inverted;
        @include icon-inherits-color;
        display: flex;
        align-items: center;
        height: 100%;

        &:disabled {
            pointer-events: none;
            opacity: .5;
        }

        &--strong {
            padding: 0 9px;
            background: $color__black--30;
            border-radius: 2px;
        }

        .header__nav-item--active & {
            color: $color__white;
        }

        .icon {
            position: relative;
            top: 1px;
        }

        .icon--user {
            margin-right: .25rem;
        }

        .icon--downarrow {
            margin-left: .25rem;
        }

        .header__nav-dropdown--open & {
            @include text--strong-inverted;

            .icon--downarrow {
                transform: scaleY(-1);
            }
        }
    }

    &__nav-dropdown-content {
        display: none;
        position: absolute;
        background: $color__deep-blue;
        border-radius: 2px;
        z-index: 10;

        // Matching .analysis
        top: calc(2.25rem + var(--space));
        left: 0;
        width: var(--analysis--width);

        &--right {
            left: auto;
            right: -10px;
        }

        &--alerts, &--primary-nav {
            position: fixed;
            left: auto;
            right: var(--space);
            top: calc(var(--header-height) + var(--space));
        }

        .header__nav-dropdown--open & {
            display: block;
        }
    }

    &--subheader {
        background-color: $color__white;
        box-shadow: none;
        height: auto;

        & .header__primary-nav {
            flex-wrap: wrap;
            gap: 0.5rem 1.25rem;

            &--align-right {
                margin-left: auto;
                margin-right: 0;
                text-align: right;
            }

            @include smaller-than-or-eq(mobile) {
                flex-wrap: nowrap;
                gap: 0.75rem;
                margin-right: 0.75rem;
            }
        }

        & .header__nav-item {
            @include text--strong-blue;
            border: 0px;
            border-bottom: 1px solid transparent;
            padding: 0.5rem 0;
            margin-left: 0;
            margin-bottom: -1px; // to overlap active border with header border

            &--active {
                @include text--strong;
                border-bottom-color: $color__black;
                pointer-events: none;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .review-data-link {
        display: none;
    }
}
