.flag {
    width: 20px;
    border: 1px solid $color__black--10;
    position: relative;
    top: 2px;

    &--large {
        width: 23px;
        top: 1px;
    }

    &--no-border {
        border: none
    }

    &__initials {
        width: 20px;
    }
}