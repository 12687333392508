.key-value {
    border-bottom: 1px solid $color__black--10;

    &--large, &--xlarge {
        border-bottom: none;
    }

    &__title {
        @include text--heading-xs;
        margin-bottom: .25rem;
    }

    &__pair {
        display: flex;
        align-items: baseline;
        margin-bottom: .25rem;

        .key-value--xlarge & {
            padding: 1.5rem 0;
            margin-bottom: 0;
            border-bottom: 1px solid $color__black--10;

            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
            }
        }

        .key-value--large & {
            padding: 1rem 0;
            margin-bottom: 0;
            border-bottom: 1px solid $color__black--10;

            &:first-child {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }

            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__key {
        @include text--normal;
        max-width: 8rem;
        flex: auto;

        .key-value--large &, .key-value--xlarge & {
            max-width: 25%;
            min-width: 8rem;
            @include text--dark;
        }

        .key-value--large &--light, .key-value--xlarge &--light {
            max-width: 25%;
            min-width: 8rem;
            @include text--normal;
        }
    }

    &__value {
        @include text--normal;
        flex: 1;

        @include smaller-than(mobile) {
            text-align: right;
        }

        &--flex {
            display: flex;
            align-items: baseline;

            @include smaller-than(mobile) {
                display: block;

                &--keep-flex {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        &--short {
            max-width: 35rem
        }

        .key-value--large & {
            @include text--dark;
        }

        .key-value--large.key-value--large--light & {
            @include text--normal;
        }
    }
}