// Utility to use `div`s as tables to take advantage of the structure on smaller screens
.table {
    display: flex;
    flex-wrap: wrap;

    border-bottom: 1px solid $color__black--10;

    &--border {
        &--none {
            border-bottom: none;
        }

        &--full {
            border: 1px solid $color__black--10
        }
    }

    &--header {
        &--row {
            & .table__row:first-child {
                background-color: $color__black--05;
            }
        }

        &--column {
            & .table__row .table__cell:first-child {
                background-color: $color__black--05;
            }
        }
    }

    &--small {
        & .table__cell {
            padding: .25rem
        }
    }

    &--large {
        padding: 0 1rem;

        & .table__cell {
            padding: 1rem
        }

        & .table__row--header .table__cell {
            padding-top: .62rem;
            padding-bottom: .62rem;
        }
    }

    &--white {
        background-color: $color__white;
        border-radius: 4px;
        border: 1px solid $color__black--10;
    }

    &__title {
        padding: 1rem;
        border-bottom: 1px solid $color__black--10;
        width: 100%;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        border-bottom: 1px solid $color__black--10;

        &--border--none {
            border: none;
        }

        &--border--top {
            border-bottom: none;
            border-top: 1px solid $color__black--10;
        }

        &--button:hover, &--button:focus {
            background-color: $color__black--03;
        }

        &--underneath {
            background-color: $color__black--03;
            border-top: 1px solid $color__black--10;
            border-bottom: 1px solid $color__black--10;
            margin-bottom: 1rem;
        }

        &--inset {
            @include smaller-than-or-eq(mobile) {
                border-top: 1px solid $color__black--10;
                margin-left: 1rem;
            }
        }

        &:last-child {
            border-bottom: none;

            & .table__cell {
                border-bottom: none;
            }

            & .table__cell__inner {
                border-bottom: none;
            }
        }

        &--header {
            align-items: flex-end;

            + .table__row--border--top {
                border-top: none;
            }
        }
    }


    &__cell {
        box-sizing: border-box;
        border-right: 1px solid $color__black--10;
        padding: 0.5rem;

        &:last-child {
            border-right: none;
        }

        // spans from 1 to 12
        &--span1 { width: calc(100% / 12) }
        &--span2 { width: calc(100% / 12 * 2) }
        &--span3 { width: calc(100% / 12 * 3) }
        &--span4 { width: calc(100% / 12 * 4) }
        &--span5 { width: calc(100% / 12 * 5) }
        &--span6 { width: calc(100% / 12 * 6) }
        &--span7 { width: calc(100% / 12 * 7) }
        &--span8 { width: calc(100% / 12 * 8) }
        &--span9 { width: calc(100% / 12 * 9) }
        &--span10 { width: calc(100% / 12 * 10) }
        &--span11 { width: calc(100% / 12 * 11) }
        &--span12 { width: 100% }

        &--border--none {
            border-right: none
        }

        &--border-bottom {
            border-bottom: 1px solid $color__black--10
        }

        &--border-top {
            border-top: 1px solid $color__black--10
        }

        &--padding--none {
            padding: 0
        }

        &--padding-thin {
            padding: 0.25rem;
        }

        &--right {
            text-align: right;
        }

        &--bottom {
            align-self: flex-end;
        }

        &--padding {
            &--xlarge {
                padding: 0.5rem 2rem
            }
        }

        .table &--no-padding {
            padding: 0
        }

        .table__row--inset & {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1rem;
            margin-bottom: 1rem;

            @include smaller-than-or-eq(mobile) {
                margin-bottom: 0;
            }

            .table__row__inset-border {
                border-left: 1px solid $color__black--10;
                padding-left: 1rem;

                @include smaller-than-or-eq(mobile) {
                    border-left: none;
                    padding-left: 0;
                }
            }
        }
    }


    @include smaller-than-or-eq(mobile) {
        // only
        &--mobile {
            .table__cell {
                width: 100% !important;
                max-width: 100% !important;

                border-right: none;
                border-bottom: none;
                padding-top: 0.25rem;
                padding-bottom: 0;

                &--right {
                    text-align: left;
                }

                &:first-child {
                    padding-top: 1rem;
                }

                &:last-child {
                    padding-bottom: 1rem;
                }

                &--border {
                    border-bottom: 1px solid $color__black--10
                }

                &--no-padding {
                    .table--large & {
                        padding: 1rem
                    }
                }

                &--padding {
                    &--xlarge {
                        padding: 0.5rem
                    }
                }
            }
        }
    }

    &__cell__inner {
        border-top: 1px solid $color__black--10;
        padding: 0.5rem 0;

        &--border {
            &--none {
                border: none
            }
        }
    }

    &__sort {
        position: relative;

        &:before {
            content: '';
            height: 12px;
            width: 10px;
            position: absolute;
            bottom: 3px;
            left: -14px;
            background: $color__white url(/svg/icon-sort-up.svg) no-repeat left top;
            background-size: 100% 100%;
            z-index: 1;
            display: none;

            .table__cell:first-child & {
                right: -14px;
                left: auto;
            }
        }


        &:hover {
            &:before {
                display: block;
                opacity: 1;
            }
        }

        &--desc {
            &:hover {
                &:before {
                    background-image: url(/svg/icon-sort-down.svg);
                }
            }
        }
    }

    &__sorted {
        &:before {
            display: block;
        }

        &--asc {
            &:before {
                background-image: url(/svg/icon-sort-up.svg);
            }
        }

        &--desc {
            &:before {
                background-image: url(/svg/icon-sort-down.svg);
            }
        }
    }

    &__tabs {
        border-bottom: 1px solid transparent;
        margin-bottom: -1px;
    }

    &__tab {
        @include text--link;
        font-weight: 500;
        margin-right: 1.5rem;
        padding: .25rem 0;
        margin-bottom: -1px;

        &:last-child {
            margin-right: 0;
        }

        &:disabled {
            opacity: 1;
        }

        &--current {
            color: $color__black;
            border-bottom: 1px solid $color__black;

            &:hover, &:focus {
                color: $color__black;
            }
        }
    }
}