.alert {
    @include font-size(1);
    background: $color__white;
    line-height: calc(23/16);
    border: 1px solid $color__black--10;
    border-radius: 2px;
    border-left: 3px solid $color__alert;
    padding: .75rem 1rem;

    &__title {
        margin-bottom: 1rem;
        color: $color__black;
    }

    &__text {
        margin-bottom: 1rem;
    }

    *:last-child {
        margin-bottom: 0;
    }

    &--info {
        border-left-color: $color__blue;
    }

    .alert__text-sm & {
        @include font-size(.875);
    }
}