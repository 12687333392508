.top-notifications {
    top: var(--space);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include smaller-than(1288px) {
        align-items: flex-start;
        left: calc(var(--analysis--width) + (var(--space) * 2));
        transform: none;
    }

    @include smaller-than(tablet) {
        display: none;
    }

    @media print {
        display: none;
    }
}