.icon {
    // Icons should never shrink
    flex-shrink: 0;

    &--smaller {
        width: 0.65rem;
        height: 0.65rem;
    }

    &--small {
        width: 0.75rem;
        height: 0.75rem;
    }

    &--smedium {
        width: 0.82rem;
        height: 0.82rem;
    }

    &--medium {
        width: 0.90rem;
        height: 0.90rem;
    }

    &--medium-width {
        width: 0.90rem;
    }

    &--large {
        width: 1rem;
        height: 1rem;
    }

    &--large2 {
        width: 1.1rem;
        height: 1.1rem;
    }

    &--larger {
        width: 1.25rem;
        height: 1.25rem;
    }

    &--fixed-size {
        &--6px {
            width: 6px;
            height: 6px;
        }

        &--10px {
            width: 10px;
            height: 10px;
        }

        &--11px {
            width: 11px;
            height: 11px;
        }

        &--15px {
            width: 15px;
            height: 15px;
        }

        &--3rem {
            min-height: 3rem;
            min-width: 3rem;
        }
    }

    &--fixed-height {
        &--10px {
            height: 10px;
            width: auto;
        }

        &--1rem {
            height: 1rem;
            width: auto;
        }
    }

    &--prefix {
        margin-right: 0.25rem;

        &--large {
            margin-right: 0.5rem;
        }

        &--larger {
            margin-right: 0.65rem;
        }

        &--largest {
            margin-right: 0.75rem
        }

        &--bullet-15 {
            margin-right: calc(var(--bullet-left) - 15px);
        }
    }

    &--suffix {
        margin-left: 0.25rem;

        &--small {
            margin-left: 0.15rem;
        }
    }

    &--offset-top {
        position: relative;
        top: 1px;

        &--2px {
            position: relative;
            top: 2px;
        }

        &--3px {
            position: relative;
            top: 3px;

            &--neg-margin-top {
                margin-top: -3px;
            }
        }

        &--4px {
            position: relative;
            top: 4px;
        }
    }

    &--neg-offset-top {
        position: relative;
        top: -1px;
    }

    &--offset-right {
        right: 1px
    }

    &--neg-offset-right {
        position: relative;
        right: -1px;
    }

    &--neg-offset-left {
        position: relative;
        left: -1px;

        &--2px {
            left: -2px
        }
    }

    &--disabled {
        color: $color__black--40;
    }

    &--shape--circle {
        display: inline-block;
        border-radius: 50%;
    }

    &--pink, &--fishing {
        color: $color__pink-bar;
    }

    &--black--50 {
        color: $color__black--50;

        &:hover {
            color: $color__black--50;
        }
    }

    &--red--hover:hover {
        color: $color__alert
    }

    &--red {
        color: $color__risk-red;
    }

    &--grey {
        color: $color__risk-grey
    }

    &--grey-F2 {
        color: $color__grey--F2;
    }

    &--white {
        color: white;
    }

    &--white--60 {
        color: $color__white--60;
    }

    &--orange {
        color: $color__risk-orange;
    }

    &--yellow {
        color: $color__risk-yellow;
    }

    &--green {
        color: $color__no-risk--green
    }

    &--dark-blue {
        color: $color__risk-dark-blue;
    }

    &--rainbow {
        background: transparent url(/svg/circle-rainbow.svg) no-repeat 0 1px;
        color: transparent;
    }

    &--orange-background {
        background-color: $color__risk-orange;
    }

    &--yellow-background {
        background-color: $color__risk-yellow;
    }

    &--dark-blue-background {
        background-color: $color__risk-dark-blue;
    }

    &--grey-background {
        background-color: $color__risk-grey;
    }

    &--red-background {
        background-color: $color__risk-red;
    }

    &--grey-F2-background {
        background-color: $color__grey--F2;
    }

    &--circle {
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
    }

    &--pink-bar {
        fill: $color__pink-bar
    }

    &--risk-lg {
        height: 14px;
        width: 14px;
    }

    &--bullet {
        padding-left: var(--bullet-left);
        line-height: 1.29;

        &.ui__panel__details__card {
            background-position-y: 13px;
        }
    }

    &--vessel {
        background: transparent url(/svg/icon-vessel.svg) no-repeat 0 5px;
        background-size: 15px 8px;

        &--dark-grey {
            background: transparent url(/svg/icon-vessel-dark-grey.svg) no-repeat 0 5px;
        }

        &--bar {
            background: transparent url(/svg/icon-bar.svg) no-repeat 0 8px;
        }
    }

    &--eye {
        background: transparent url(/svg/eye-open-dark.svg) no-repeat center center;
        width: 14px;
        height: 9px;
        opacity: 0.5;
        margin-left: 0.25rem;
        flex-shrink: 0;

        &--closed {
            background: transparent url(/svg/eye-closed-dark.svg) no-repeat center center;
            opacity: 0.7; // Opacity is greater than open state because parent opacity does most of the dimming
        }

        &--dim {
            opacity: 0.3;
        }
    }

    &--rf {
        background: transparent url(/svg/icon-rf-grey.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--rf-grey {
        background: transparent url(/svg/icon-rf-grey.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--rf-possible {
        background: transparent url(/svg/icon-det-possible.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--rf-missing {
        background: transparent url(/svg/icon-det-missing.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--rf-match {
        background: transparent url(/svg/icon-det-match.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--rf-match-grey {
        background: transparent url(/svg/icon-rf-match-grey.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--sar {
        background: transparent url(/svg/icon-sar.svg) no-repeat 1px 5px;
        background-size: 13px 9px;
    }

    &--opt-missing, &--sar-missing, &--air-missing {
        background: transparent url(/svg/icon-det-missing.svg) no-repeat 2px 5px;
        background-size: 10px 10px;
    }

    &--opt-possible, &--sar-possible, &--air-possible {
        background: transparent url(/svg/icon-det-possible.svg) no-repeat 2px 5px;
        background-size: 10px 10px;
    }

    &--opt-match, &--sar-match, &--air-match {
        background: transparent url(/svg/icon-det-match.svg) no-repeat 2px 5px;
        background-size: 10px 10px;
    }

    &--det-match-selected {
        background: transparent url(/svg/icon-det-match-selected.svg) no-repeat 2px 3px;
        background-size: 12px 12px;
    }

    &--det-possible-selected {
        background: transparent url(/svg/icon-det-possible-selected.svg) no-repeat 2px 3px;
        background-size: 12px 12px;
    }

    &--det-missing-selected {
        background: transparent url(/svg/icon-det-missing-selected.svg) no-repeat 2px 3px;
        background-size: 12px 12px;
    }

    &--det-match-lg {
        background: transparent url(/svg/icon-det-match.svg) no-repeat 2px 3px;
        background-size: 12px 12px;
    }

    &--det-possible-lg {
        background: transparent url(/svg/icon-det-possible.svg) no-repeat 2px 3px;
        background-size: 12px 12px;
    }

    &--det-invalid-lg {
        background: transparent url(/svg/icon-det-invalid.svg) no-repeat 2px 2px;
        background-size: 12px 12px;
    }

    &--det-missing-lg {
        background: transparent url(/svg/icon-det-missing.svg) no-repeat 2px 3px;
        background-size: 12px 12px;
    }

    &--loitering {
        background: transparent url(/svg/icon-loitering.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--loitering-grey {
        background: transparent url(/svg/icon-loitering-grey.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--ais-data-gap {
        background: transparent url(/svg/icon-ais-data-gap.svg) no-repeat 3px 1px;
        background-size: 3px 15px;
    }

    &--encounter {
        background: transparent url(/svg/icon-encounter.svg) no-repeat 1px 2px;
        background-size: 13px 12px;
    }

    &--encounter-red {
        background: transparent url(/svg/icon-encounter-on-track.svg) no-repeat 1px 2px;
        background-size: 16px 16px;
    }

    &--encounter-grey {
        background: transparent url(/svg/icon-encounter-grey.svg) no-repeat 1px 2px;
        background-size: 13px 12px;
    }

    &--anomaly {
        background: transparent url(/svg/icon-anomaly.svg) no-repeat 1px 2px;
        background-size: 11px 13px;
        position: relative;
        top: 1px;
        width: 14px;
        height: 14px;
    }

    &--alien {
        background: transparent url(/svg/timeline-alien-dark.svg) no-repeat 1px 2px;
        background-size: 12px 12px;
    }

    &--optical, &--opt, &--air {
        background: transparent url(/svg/timeline-optical.svg) no-repeat 1px 3px;
        background-size: 13px 11px;
    }

    &--fishing-activity {
        background: transparent url(/svg/icon-fishing-activity.svg) no-repeat 1px 3px;
        background-size: 13px 11px;
    }

    &--money {
        background: transparent url(/svg/money.svg) no-repeat 0 4px;
        background-size: 18px 18px;
    }

    &--diamond {
        background: transparent url(/svg/diamond.svg) no-repeat 0 2px;
        background-size: 17px 17px;
    }

    &--rectangle {
        background: transparent url(/svg/rectangle.svg) no-repeat left center;
        background-size: 14px 11px;
    }

    &--ais-white {
        background: transparent url(/svg/ais-white.svg) no-repeat 0 4px;
        background-size: 14px 14px;
    }

    &--ais-red {
        background: transparent url(/svg/ais-red.svg) no-repeat 0 4px;
        background-size: 14px 14px;
    }

    &--ais-blue {
        background: transparent url(/svg/ais-blue.svg) no-repeat 0 4px;
        background-size: 14px 14px;
    }

    &--ais-grey {
        background: transparent url(/svg/ais-grey.svg) no-repeat 0 4px;
        background-size: 14px 14px;
    }

    &--light-circle {
        background: transparent url(/svg/light-circle.svg) no-repeat 0 4px;
        background-size: 14px 14px;
    }

    &--port {
        background: transparent url(/svg/icon-port.svg) no-repeat 0 5px;
        background-size: 8px 8px;
    }

    &--port-red {
        background: transparent url(/svg/icon-port-red.svg) no-repeat 0 5px;
        background-size: 8px 8px;
    }

    &--bug {
        background: transparent url(/svg/bug.svg?v=2) no-repeat 0 2px;
        background-size: 12px 13px;
    }

    &--close-dialog {
        position: absolute;
        background: transparent url(/svg/close.svg) no-repeat 0 0;
        background-size: 18px 18px;
        right: 4px;
        top: 4px;
        width: 18px;
        height: 18px;
    }

    &--det-match {
        height: 6px;
        width: 6px;
        background-color: $color__det-match;
        border-radius: 1px;
    }

    &--det-possible {
        height: 6px;
        width: 6px;
        background-color: $color__risk-yellow;
        border-radius: 1px;
    }

    &--det-missing {
        height: 6px;
        width: 6px;
        background-color: $color__det-missing;
        border-radius: 1px;
    }

    &--det-invalid {
        height: 6px;
        width: 6px;
        background-color: $color__det-invalid;
        border-radius: 1px;
    }

    &--downarrowLarge {
        path {
            stroke: $color__text-grey;
        }
    }

    &--rightarrowAlt {
        path {
            stroke: $color__text-grey;
            opacity: .5;
        }
    }

    &--air {
        background: transparent url(/svg/icon-aerial.svg) no-repeat 0 2px;
        background-size: 14px 13px;
    }

    &--trash {
        path {
            fill: $color__black--50;
        }

        &:hover {
            path {
                fill: $color__alert;
            }
        }
    }

    &--stroke-grey {
        path {
            stroke: $color__text-grey;
            opacity: .5;
        }
    }

    &--fill-white {
        path {
            fill: $color__white;
        }
    }

    &--fill-black-60 {
        path {
            fill: $color__black--60;
        }
    }

    &--fill-blue {
        path {
            fill: $color__blue;
        }
    }

    &--rotate180 {
        transform: rotate(180deg);
    }

    &--color-white {
        color: $color__white;
    }

    &--vessel-instance-label {
        background-color: $color__black--10;
        text-transform: uppercase;
        margin-left: 0.25rem;
        padding: 1px 0.2rem 2px 0.2rem;
        border-radius: 10%;
        align-items: center;
        word-break: keep-all;

        @include text--normal;
        @include text--small;
        @include text--bold;
    }
}
