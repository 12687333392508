.footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 0 1rem;
    background-color: $color__grey--F0;

    @include smaller-than-or-eq(mobile) {
        position: static;
    }

    &__inner {
        display: flex;
        align-items: center;
        border-top: 1px solid $color__black--10;
        height: 43px;
    }

    &__logo {
        display: block;
        height: 30px;
        width: 30px;
    }
}
