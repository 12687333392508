.data-ready {

    pointer-events: all;

    padding: 2px 12px;
    border-radius: 30px;
    z-index: 12;

    // Green dot
    &:before {
        display: inline-block;
        margin-right: 6px;

        content: '';
        height: 8px;
        width: 8px;

        background-color: $color__data-ready--green;
        border-radius: 50%;
    }

    background-color: $color__black--50;
    @include font-size(0.875);
    font-family: $font__soehne;
    line-height: 23px;
    color: $color__white;

    @media print {
        display: none;
    }

    &--bar {
        color: $color__white--50;
    }

    &--link {
        color: $color__light-blue-link;
    }

}