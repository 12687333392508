.event-list {
    width: 100%;

    &__report-wrapper {
        display: flex;
        align-items: baseline;

        @include smaller-than-or-eq(mobile) {
            display: block;
        }
    }

    &__report-filters {
        flex: 1;
        position: relative;
        margin-bottom: 1rem;
    }

    &--report {
        flex: 3;
        margin-left: -1rem;
    }

    &__group {
        &--highlighted {
            background-color: $color__grey--F2;

            // Nested highlighted event
            .event-list__extra & {
                margin-left: -.75rem;
                padding-left: .75rem;
            }
        }

        // This specifically targets and adds space below the last journey activity (if one exists)
        .event-list__item--journey-activity:last-child {
            padding-bottom: .6rem;
        }
    }

    // Wraps elements which share a track to avoid the complexity of lengthening/shortening the track over
    // multiple elements which may or may not be rendered
    &__track-wrapper {
        position: relative;
    }

    &__item {
        position: relative;
        padding: 0rem 1rem;


        // Only use this for journey activities due to the :last-child rule above
        &--journey-activity {
            padding-bottom: .2rem;
        }

        &--no-padding {
            padding: 0;
        }

        &--summary {
            left: -1rem;
            padding-right: 0;
        }

        &--port {
            padding-top: .2rem;
            padding-bottom: .2rem;
        }

        &--spacer {
            padding: 0;
            height: .4rem;
        }
    }

    &__track {
        margin-left: 4px;
        border-left: 2px solid $color__black--15;
        position: absolute;
        left: 1rem;
        right: calc(100% - 6px);
        top: 0;
        bottom: 0;

        &--dashed {
            border-left-style: dashed;
        }

        &--blank {
            border-color: transparent;
        }

        &--no-left {
            left: 0rem;
        }

        &--indent {
            top: .59rem;
            bottom: -.75rem;
        }

        &--indent2 {
            top: .81rem;
            bottom: -.75rem;
        }

        &--indent2-top {
            top: .81rem;
        }

        &--indent-top {
            top: .77rem;
        }

        &--indent-lg {
            top: .57rem;
            bottom: -1.35rem;
        }

        &--indent-bottom-lg {
            bottom: -1.35rem;
        }

        &--indent-bottom {
            bottom: -.55rem;
        }
    }

    &__value {
        @include text--dark;
        padding-top: .4rem;
        padding-bottom: .4rem;
        margin-left: 6px;
        padding-left: .75rem;
        height: 100%;

        &--journey {
            padding-left: .75rem;
            margin-bottom: .1rem;
            cursor: pointer;

            &--expanded {
                margin-bottom: 0;
            };
        };

        &--clipped {
            margin-bottom: 0;
        };

        &--no-padding{
            padding-left: 0;
        }

        &--duration {
            padding-top: .6rem;
            padding-bottom: .6rem;
        }

        &--align-left {
            padding-left: 0;
            margin-left: 0;
        }

        &--summary {
            padding-top: 0rem;
        }

        &--heavy-grey {
            font-weight: 500;
            line-height: calc(23/19);
            color: rgba(0,0,0,.6);
        }
    }

    &__journey-port {
        @include text--dark;
        padding-top: .4rem;
        padding-bottom: .4rem;
        margin-left: 6px;
        padding-left: .75rem;

        &--expanded {
            margin-bottom: 0;
        }
    }

    &__journey-activity {
        cursor: pointer;
        padding-bottom: .1rem;
        margin-left: 6px;
        padding-left: .75rem;

        &--expanded {
            margin-bottom: 0;
        }
    }

    &__nested-event {
        @include text--dark;
        padding-top: .4rem;
        padding-bottom: .5rem;
        margin-left: 6px;
        padding-left: .7rem;
        height: 100%;
    }

    &__dropdown-open {
        margin-bottom: .2rem;
    }

    &__extra {
        @include text--normal;
        max-width: 500px;

        &--mb {
            margin-bottom: .5rem;
        }
    }

    &__button {
        .event-list__value:hover &, .event-list__value:focus &,
        .event-list__nested-event:hover &,
        .event-list__nested-journey-activity:focus & {
            color: $color__blue-link;
        }

        &--port {
            font-weight: 500;
        }

        &--heavy-grey {
            font-weight: 500;
            line-height: calc(23/19);
            color: rgba(0,0,0,.6);
        }
    }

    &__warning {
        color: $color__alert;

        .event-list__value:hover &, .event-list__value:focus & {
            color: $color__dark-alert;
        }
    }

    &__transshipment {
        color: $color__ais-inferred-transshipment--selected;

        path {
            fill: $color__ais-inferred-transshipment--selected;
        }

        &:hover {
            path {
                fill: $color__ais-inferred-transshipment--selected;
            }
        }
    }

    &__icon {
        position: absolute;
        top: .4rem;
        left: 21px;
        transform: translateX(-50%);
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        background-color: $color__white;

        &--chop {
            top: .26rem;
        }

        &--less-left{
            left: .3rem;
        }

        &--port {
            height: 12px;
            width: 8px;
            top: calc(.4rem + 3px);

            &-nested {
                top: 3px;
            }

            &-journey{
                top: calc(.6rem + 3px);
            }
        }

        &--port-red {
            height: 12px;
            width: 8px;
            top: calc(.4rem + 3px);

            &-nested {
                top: 3px;
            }

            &-journey{
                top: calc(.6rem + 3px);
            }
        }

        &--encounter {
            height: 16px;
            width: 16px;

            &-nested {
                top: 0px;
            }
        }

        &--fishing {
            height: 21px;
            width: 4px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &-nested {
                top: 0px;
            }
        }

        &--anomaly {
            height: 16px;
            width: 16px;
            top: calc(.4rem + 3px);

            &-nested {
                top: 3px;
            }
        }

        &--alert {
            height: 16px;
            width: 10px;
            top: calc(.4rem + 2px);

            &-nested {
                top: 2px;
            }
        }

        &--tag {
            height: 16px;
            width: 12px;
            top: calc(.4rem + 2px);

            &-nested {
                top: 2px;
            }
        }

        &--note {
            height: 16px;
            width: 12px;
            top: calc(.4rem + 2px);

            &-nested {
                top: 2px;
            }
        }

        &--loitering {
            height: 21px;
            width: 2px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &-nested {
                top: 0px;
            }
        }

        &--awol {
            height: 24px;
            width: 2px;

            &-nested {
                top: 0px;
            }
        }

        &--awol-loitering {
            height: 14px;
            width: 10px;
            top: calc(.4rem + 3px);

            &-nested {
                top: 3px;
            }
        }

        &--opt, &--air {
            height: 17px;
            width: 13px;
            top: calc(.4rem + 3px);

            &-nested {
                top: 3px;
            }
        }

        &--sar {
            height: 17px;
            width: 13px;
            top: calc(.4rem + 4px);

            &-nested {
                top: 4px;
            }
        }

        &--rf {
            height: 17px;
            width: 13px;
            top: calc(.4rem + 3px);

            &-nested {
                top: 3px;
            }
        }

        &--detc {
            height: 14px;
            width: 10px;
            top: calc(.4rem + 2px);

            &-nested {
                top: 2px;
            }
        }

        &--dest {
            height: 14px;
            width: 10px;
            top: calc(.4rem + 2px);

            &-nested {
                top: 2px;
            }
        }
    }
}