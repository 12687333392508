// Cookies consent styles
@import "../variables/_typography.scss";

#cc-main {
    --cc-font-family: #{$font__soehne};
    --cc-btn-primary-bg: #{$color__blue};
    --cc-btn-primary-border-color: #{$color__blue};
    --cc-btn-primary-color: #{$color__white};
    --cc-btn-primary-hover-bg: #{$color__dark-blue};
    --cc-btn-primary-hover-border-color: #{$color__dark-blue};
    --cc-btn-primary-hover-color: #{$color__white};
    --cc-btn-border-radius: .25rem;

    .cm__btn, .pm__btn, .cm__title, .pm__title, .pm__section-title {
        font-weight: 500 !important; // Need to override library styles with !important
    }
}