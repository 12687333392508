.notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: calc(var(--timeline-height) + var(--space) + 60px);
    z-index: 150;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    &__item {
        background: $color__black--70;
        @include text--white;
        border-radius: 100px;
        padding: 7px 15px 9px;
        pointer-events: all;
    }
}
