$font__roboto__medium: RobotoMedium, sans-serif;
$font__tempos: TiemposHeadlineMedium, serif;

$font__soehne: Soehne, sans-serif;

@font-face {
    font-family: 'TiemposHeadlineMedium';
    src: url('/fonts/tiempos-headline-web-medium.eot'); /* IE9 Compat Modes */
    src: url('/fonts/tiempos-headline-web-medium.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/tiempos-headline-web-medium.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-buch.eot'); /* IE9 Compat Modes */
    src: url('/fonts/soehne-web-buch.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/soehne-web-buch.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/soehne-web-buch.woff') format('woff'); /* Modern Browsers */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-buch-kursiv.eot'); /* IE9 Compat Modes */
    src: url('/fonts/soehne-web-buch-kursiv.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/soehne-web-buch-kursiv.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/soehne-web-buch-kursiv.woff') format('woff'); /* Modern Browsers */
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-kraftig.eot'); /* IE9 Compat Modes */
    src: url('/fonts/soehne-web-kraftig.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/soehne-web-kraftig.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/soehne-web-kraftig.woff') format('woff'); /* Modern Browsers */
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-kraftig-kursiv.eot'); /* IE9 Compat Modes */
    src: url('/fonts/soehne-web-kraftig-kursiv.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/soehne-web-kraftig-kursiv.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/soehne-web-kraftig-kursiv.woff') format('woff'); /* Modern Browsers */
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-halbfett.eot'); /* IE9 Compat Modes */
    src: url('/fonts/soehne-web-halbfett.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/soehne-web-halbfett.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/soehne-web-halbfett.woff') format('woff'); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-halbfett-kursiv.eot'); /* IE9 Compat Modes */
    src: url('/fonts/soehne-web-halbfett-kursiv.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/soehne-web-halbfett-kursiv.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/soehne-web-halbfett-kursiv.woff') format('woff'); /* Modern Browsers */
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SuisseIntlRegular';
    src: url('/fonts/SuisseIntl-Regular-WebS.eot'); /* IE9 Compat Modes */
    src: url('/fonts/SuisseIntl-Regular-WebS.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/SuisseIntl-Regular-WebS.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/SuisseIntl-Regular-WebS.woff') format('woff'), /* Modern Browsers */
    url('/fonts/SuisseIntl-Regular-WebS.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('/fonts/SuisseIntl-Regular-WebS.svg#SuisseIntl') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SuisseIntlBook';
    src: url('/fonts/SuisseIntl-Book-WebS.eot'); /* IE9 Compat Modes */
    src: url('/fonts/SuisseIntl-Book-WebS.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/SuisseIntl-Book-WebS.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/SuisseIntl-Book-WebS.woff') format('woff'), /* Modern Browsers */
    url('/fonts/SuisseIntl-Book-WebS.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('/fonts/SuisseIntl-Book-WebS.svg#SuisseIntl') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SuisseIntlSemiBold';
    src: url('/fonts/SuisseIntl-SemiBold-WebS.eot'); /* IE9 Compat Modes */
    src: url('/fonts/SuisseIntl-SemiBold-WebS.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/SuisseIntl-SemiBold-WebS.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/SuisseIntl-SemiBold-WebS.woff') format('woff'), /* Modern Browsers */
    url('/fonts/SuisseIntl-SemiBold-WebS.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('/fonts/SuisseIntl-SemiBold-WebS.svg#SuisseIntl') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SuisseIntlMedium';
    src: url('/fonts/SuisseIntl-Medium-WebS.eot'); /* IE9 Compat Modes */
    src: url('/fonts/SuisseIntl-Medium-WebS.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/SuisseIntl-Medium-WebS.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/SuisseIntl-Medium-WebS.woff') format('woff'), /* Modern Browsers */
    url('/fonts/SuisseIntl-Medium-WebS.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('/fonts/SuisseIntl-Medium-WebS.svg#SuisseIntl') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

/* roboto-700 - latin */
@font-face {
    font-family: 'RobotoMedium';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

