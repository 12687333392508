.tile {
    flex: 1;
    margin: 0 2rem 2rem 0;
    background: $color__white;
    max-width: 420px;
    min-width: 288px;
    padding: 1rem;
    border: 1px solid $color__black--10;
    box-shadow: 0px 0px 5px $color__black--10;
    border-radius: 4px;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__header {
        border-bottom: 1px solid $color__black--10;
        padding-bottom: .5rem;
        margin-bottom: .75rem;
    }

    &__title {
        @include text--lg-strong;
    }

    &__links {
        margin-top: .5rem;
    }

    &__link {
        margin-right: 1rem;
    }

    &__img {
        display: block;
        width: 100%;
        margin-top: .75rem;
        border-radius: 2px;
    }
}