.layers {
    &__container {
        position: absolute;
        top: var(--space);
        right: calc(var(--analysis--width) + (var(--space) * 3) + var(--zoom--width));
        z-index: 5;
        pointer-events: auto;
        width: 34px;
        height: 34px;

        &.layers--measurement {
            right: calc(var(--analysis--width) + (var(--space) * 4) + var(--zoom--width) + 34px);
        }

        @include smaller-than(desktop) {
            top: auto;
            right: auto;
            bottom: 124px;
            left: 44px;

            &.layers--measurement {
                left: var(--space);
            }
        }

        @media print {
            display: none;
        }

        &--popup-open {
            z-index: 15;

            .__react_component_tooltip {
                display: none;
            }
        }
    }

    &__open-btn {
        display: block;
        width: 34px;
        height: 34px;
        background: $color__white url(/svg/layers.svg) no-repeat center center;
        border-radius: 2px;

        .layers--measurement & {
            background-image: url(/svg/ruler-horizontal.svg);
        }
    }

    &__popup {
        display: none;
        position: absolute;
        right: 0;
        top: calc(100% + var(--space));
        width: var(--analysis--width);
        background-color: $color__white;
        box-shadow: 0px 0px 20px $color__black--40;
        border-radius: 2px;
        flex-direction: column;
        overflow: hidden;

        .layers--measurement & {
            width: auto;
        }

        .layers__container--popup-open & {
            display: flex;
        }

        @include smaller-than(desktop) {
            position: fixed;
            top: 136px;
            bottom: auto;
            right: auto;
            left: var(--space);
        }
    }

    &__popup-body {
        padding: 0 1rem;
        overflow: auto;

        // Extra padding at the bottom when the last child is open (i.e. has more than just a button)
        &:has(.option-list:last-child button + *) {
            padding-bottom: 1rem;
        }

        &--open-submenu {
            display: flex;
            flex-direction: column;
        }
    }

    &__subtext {
        padding-left: 1.4rem;
        padding-bottom: 0.5rem;
    }
}
