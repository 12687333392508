.box {
    background: $color__white;
    border-radius: 4px;
    border: 1px solid $color__black--10;
    padding: 1rem;

    &--no-padding {
        padding: 0;

        & .box__header {
            margin: 1rem 0;
        }
    }

    &--less-padding {
        padding: .75rem;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        @include smaller-than-or-eq(tablet) {
            display: block;
        }

        &--border {
            border-bottom: 1px solid $color__black--10;
            margin: 0 -1rem 1rem;
            padding: 0 1rem 1rem;
        }
    }

    &__header-subtitle {
        @include text--strong;

        &--border {
            border-bottom: 1px solid $color__black--10;
            padding-bottom: 1rem;

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    &__header-title {
        @include text--heading-m;
    }

    &__header-links {
        @include smaller-than-or-eq(tablet) {
            margin-top: .5rem;
        }
    }

    &__header-link {
        @include text--link;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    &__body {
        border-bottom: 1px solid $color__black--10;
        margin: 0 0rem 1rem;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}
