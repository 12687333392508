.ball-loader-page {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    margin:0;
    background:linear-gradient(180deg, $color__deepish-blue 0%, $color__deep-blue 42.7%);
}

.ball-loader {
    width: 68px;
    height: 30px;
    position: absolute;
    bottom: calc(var(--timeline-height) + var(--space) + 60px);
    left: 50%;
    margin-left: -34px;
    background: $color__black--30;
    border-radius: 30px;
    z-index: 100;

    .ball-loader-page & {
        bottom: 50%;
        margin-top: 15px;
    }

    &--halfway-down {
        position: fixed;
        bottom: 50%;
        margin-top: 15px;
    }
}

.ball-loader-notification {
    display: inline-block;
    width: 48px;
    position: relative;
    top: -19px;
}

.ball-loader-ball {
    will-change: transform;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: $color__white;
    position: absolute;
    top: 8px;
    animation: grow 1s ease-in-out infinite alternate;
    transform: translateX(-50%) scale(1);

    &.ball1 {
        left: 25%;

        .ball-loader-notification & {
            left: 7px;
        }
    }

    &.ball2 {
        left: 50%;
        animation-delay: 0.33s;

        .ball-loader-notification & {
            left: 24px;
        }
    }

    &.ball3 {
        left: 75%;
        animation-delay: 0.66s;

        .ball-loader-notification & {
            left: 41px;
        }
    }
}

@keyframes grow {
    to {
        transform: translateX(-50%) scale(0);
    }
}
