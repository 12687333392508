.actionlist {
    padding-top: .75rem;

    &__line {
        display: flex;
        align-items: baseline;

        &--wrap-narrow {
            @include smaller-than-or-eq(tablet) {
                display: block;
            }
        }
    }

    &__item {
        padding: 1.25rem 0 1.5rem;
        border-bottom: 1px solid $color__black--10;

        &--small {
            padding: .75rem 0;
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__item-title {
        @include text--heading-s;
    }

    &__actions {
        @include font-size(0.875);
        line-height: calc(1.64);
        display: flex;
        align-items: baseline;
    }

    &__label {
        @include font-size(0.875);
        line-height: calc(1.64);
        flex: 1 1 0;
        color: $color__black--60;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: .75rem;
    }

    &__action {
        @include remove-os-appearance;
        @include font-size(0.875);
        @include icon-inherits-color;
        font-family: $font__soehne;
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }

        .icon {
            height: .75rem;
            width: .75rem;
        }
    }

    &__detail {
        @include font-size(0.875);
        line-height: calc(1.64);
        font-family: $font__soehne;
        color: $color__black--60;
        margin-right: 3rem;

        &:last-child {
            margin-right: 0;
        }
    }

    &__warning {
        @include text--warning;
    }

    &__utils-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        @include smaller-than-or-eq(mobile) {
            margin-bottom: .75rem;
        }
    }

    &__utils {
        button {
            margin-left: 1rem;
        }
    }
}