.option-list {
    border-top: 1px solid $color__black--10;
    padding: .75rem 0;

    &:first-child {
        border-top: 0;
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &__title {
        @include text--lg-strong;
        margin-bottom: .5rem;
        align-self: flex-start;
    }

    &--scroll {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &--flex {
        display: flex;
        flex-direction: column;
    }

    &__scroll-block {
        flex: 1;
        overflow: auto;

        @media print {
            height: auto;
            overflow: visible;
        }
    }

    &--bordered {
        & .form__wrapping-label {
            border: 1px solid $color__black--10;
            padding: 0.5rem;
            border-radius: 2px;
        }
    }
}