.divided-list {
    display: flex;
    align-items: stretch;

    @include smaller-than(tablet) {
        display: block;
    }

    &__item-wrapper {
        flex: 1;
    }

    &__item {
        border-left: 1px solid $color__black--10;
        padding: 0 1rem;

        @include smaller-than(tablet) {
            border-left: none;
            border-top: 1px solid $color__black--10;
            padding: 1rem 0;
        }

        .divided-list__item-wrapper:first-child & {
            border-left: none;
            padding-left: 0;

            @include smaller-than(tablet) {
                padding-top: 0;
                border-top: none;
            }
        }
    }

    &__label {
        margin-bottom: .25rem;
    }

    &__value {
        @include text--lg-strong;
    }
}