@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 16) * 1px;
    font-size: $sizeValue * 1rem;
}

$break-mobile: 600px;
$break-tablet: 768px;
$break-desktop: 1100px;
$break-large: 1200px;

@mixin bigger-than($media) {
    @if $media == mobile {
        @media only screen and (min-width: $break-mobile + 1) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (min-width: $break-tablet + 1) { @content; }
    }
    @else if $media == desktop {
        @media only screen and (min-width: $break-desktop + 1) { @content; }
    }
    @else if $media == large {
        @media only screen and (min-width: $break-large + 1) { @content; }
    }
    @else {
        @media only screen and (min-width: $media + 1) { @content; }
    }
}

@mixin bigger-than-or-eq($media) {
    @if $media == mobile {
        @media only screen and (min-width: $break-mobile) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (min-width: $break-tablet) { @content; }
    }
    @else if $media == desktop {
        @media only screen and (min-width: $break-desktop) { @content; }
    }
    @else if $media == large {
        @media only screen and (min-width: $break-large) { @content; }
    }
    @else {
        @media only screen and (min-width: $media) { @content; }
    }
}

@mixin smaller-than($media) {
    @if $media == mobile {
        @media only screen and (max-width: $break-mobile - 1) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (max-width: $break-tablet - 1) { @content; }
    }
    @else if $media == desktop {
        @media only screen and (max-width: $break-desktop - 1) { @content; }
    }
    @else if $media == large {
        @media only screen and (max-width: $break-large - 1) { @content; }
    }
    @else {
        @media only screen and (max-width: $media - 1) { @content; }
    }
}

@mixin smaller-than-or-eq($media) {
    @if $media == mobile {
        @media only screen and (max-width: $break-mobile) { @content; }
    }
    @else if $media == tablet {
        @media only screen and (max-width: $break-tablet) { @content; }
    }
    @else if $media == desktop {
        @media only screen and (max-width: $break-desktop) { @content; }
    }
    @else if $media == large {
        @media only screen and (max-width: $break-large) { @content; }
    }
    @else {
        @media only screen and (max-width: $media) { @content; }
    }
}

@mixin icon-inherits-color {
    .icon {
        path { fill: currentColor; }
    }
}

@mixin remove-os-appearance {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
}
