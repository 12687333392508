.org-picker {
    &__trigger {
        display: flex;
        padding: 0 .5rem;
        align-items: center;
        gap: 5px;
        border-radius: 3px;
        background: $color__white--10;
        height: 1.5rem;
        margin-right: 1.25rem;
        color: $color__white--60;
        font-weight: 500;

        &:hover {
            background: $color__white--15;
        }

        @include smaller-than-or-eq(mobile) {
            margin-right: .75rem;
        }
    }

    &__panel {
        color: $color__white--70;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 12px;
        margin-left: -3px;
        background: $color__deep-blue;
        width: 18.75rem;
        max-width: 100%;
        border-radius: 0.125rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
        z-index: 10;
        overflow: auto;
    }

    &__list {
        padding-top: .75rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.625rem;

        & + & {
            border-top: 1px solid $color__white--10;
        }
    }

    &__list-header {
        padding: 0rem 1rem 0rem calc(1rem + 2px);
        display: flex;
        gap: 5px;
        align-items: center;
    }

    &__items {
        max-height: 210px;
        overflow: auto;
        padding-bottom: .75rem;
    }

    &__item {
        display: block;
        width: 100%;
        border-left: 3px solid transparent;
        padding: 0.625rem 1rem .75rem;
        color: $color__white--70;

        &--active {
            border-left-color: $color__white;
            background: $color__white--05;
        }

        &:hover {
            background: $color__white--05;
        }
    }


    &__footer {
        border-top: 1px solid $color__white--10;
        padding: .75rem 1rem;
    }

    &__enterprise {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
        border-left: 3px solid transparent;
        padding: 0.625rem 1rem .75rem .75rem;
        color: $color__white--70;
        border-bottom: 1px solid $color__white--10;
        position: relative;

        &--active {
            border-left-color: $color__white;
            background: $color__white--05;
        }

        &:hover {
            background: $color__white--05;
            color: $color__white--70;
        }
    }

    &__multi-enterprise-select {
        appearance: none;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}