.fieldlist {
    &__item {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 1rem 0;
        border-bottom: 1px solid $color__black--10;

        &:last-child {
            border-bottom: 0;
        }

        @include smaller-than-or-eq(mobile) {
            flex-wrap: wrap;
        }
    }

    &__label {
        @include font-size(1);
        line-height: calc(23/16);
        color: $color__black--60;
        order: 1;
        width: 8rem;
        padding-top: 8px;

        @include smaller-than-or-eq(mobile) {
            width: auto;
        }

        &--no-order {
            order: 0;
        }

        &--dark {
            color: $color__black;
        }
    }

    &__value {
        @include text--heading-s;
    }

    &__optbox {
        order: 2;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        width: auto;
    }

    &__field, &__fieldgroup {
        order: 2;
        flex: 1;
        color: $color__black;

        @include smaller-than-or-eq(mobile) {
            order: 4;
            flex: auto;
            margin-top: .5rem;
            width: 100%;
        }
    }

    &__field {
        &:disabled, &--disabled {
            border-color: transparent;
            background-color: transparent;

            @include smaller-than-or-eq(mobile) {
                padding-left: 0;
                margin-top: 0;
            }
        }

        &--large {
            width: 312px;

            @include smaller-than-or-eq(mobile) {
                width: 270px;
                padding-left: 0;
                margin-top: 0;
                font-size: 13.5px;
            }
        }
    }

    &__field--wrap {
        order: 2;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
    }

    &__fieldgroup {
        .fieldlist__field {
            margin-bottom: .5rem;
        }
    }

    &__actions {
        order: 3;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        padding: 11px 0 0 2rem;
    }

    &__action {
        margin-left: 1rem;
    }

    &__footer {
        border-bottom: 1px solid $color__black--10;
        padding: 1rem 0;
        margin: 0 0 1.5rem 0;
    }
}
