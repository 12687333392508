.ais-list {
    display: table;

    // Try and keep a consistent width so the width doesn't jump
    // around when loading more rows
    min-width: 760px;

    &__row {
        display: table-row;
        cursor: pointer;

        &--selected {
            background-color: $color__grey--F2;
        }
    }

    &__cell {
        display: table-cell;
        padding: .75rem;
        white-space: nowrap;
    }

    &__track {
        display: table-cell;
        border-right: 2px solid $color__black--10;
        width: 2px;
        position: relative;
        padding-left: 1rem;

        &:before {
            content: '';
            height: 10px;
            width: 2px;
            background: $color__white;
            background-position: 50% 50%;
            position: absolute;
            z-index: 1;
            left: 16px;
            top: 16px;

            .ais-list__row:first-child & {
                top: 0;
                height: 26px;
            }

            .ais-list__row--selected & {
                background: $color__grey--F2;
            }
        }
    }

    &__dot {
        height: 12px;
        width: 12px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 2;
        left: 11px;
        top: 15px;

        &--purple {
            background-image: url(/svg/ais-layers-purple.svg);
        }
        &--blue {
            background-image: url(/svg/ais-layers-blue.svg);
        }
        &--green {
            background-image: url(/svg/ais-layers-green.svg);
        }
        &--grey {
            background-image: url(/svg/ais-layers-grey.svg);
        }
    }
}