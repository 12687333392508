@mixin text--normal {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__black--60;
}

@mixin text--muted {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__black--30;
}

@mixin text--normal-inverted {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__white--70;
}

@mixin text--white {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__white;
}

@mixin text--dark {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__black;
}

@mixin text--dark-link {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__black;

    &:hover, &:focus {
        color: $color__dark-blue;
    }
}

@mixin text--blue {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__blue;
}

@mixin text--strong-blue {
    @include text--blue;
    font-weight: 500;
}

@mixin text--strong {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    font-family: $font__soehne;
    font-weight: 500;
    color: $color__black;
}

@mixin text--strong-inverted {
    @include font-size(0.875);
    font-family: $font__soehne;
    line-height: var(--standard-line-height);
    color: $color__white;
}

@mixin text--link {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    font-weight: 400;
    color: $color__blue;

    &:hover, &:focus-visible {
        color: $color__dark-blue;
    }

    &:disabled {
        opacity: .5;
        pointer-events: none;
    }
}

@mixin text--link-grey {
    color: inherit;

    &:hover, &:focus-visible {
        color: $color__blue;
    }
}

@mixin text--link-inverted {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__white--70;

    &:hover, &:focus-visible {
        color: $color__white;
    }
}

@mixin text--link-white {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__white;

    &:hover, &:focus-visible {
        color: $color__white;
    }
}

@mixin text--warning {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__alert;
}

@mixin text--warning-link {
    @include font-size(0.875);
    line-height: var(--standard-line-height);
    color: $color__alert;

    &:hover, &:focus {
        color: $color__dark-alert;
    }

    &:disabled {
        opacity: .5;
        pointer-events: none;
    }
}

@mixin text--link-uppercase {
    text-transform: uppercase;
    @include text--link;
}

@mixin text--small {
    @include font-size(.6875)
}

@mixin text--smaller {
    @include font-size(.5)
}

@mixin text--lg {
    @include font-size(1);
    line-height: calc(23/16);
    color: $color__black--60;
}

@mixin text--lg-muted {
    @include font-size(1);
    line-height: calc(23/16);
    color: $color__black--30;
}

@mixin text--lg-dark {
    @include font-size(1);
    line-height: calc(23/16);
    color: $color__black;
    font-weight: 400;
}

@mixin text--lg-strong {
    @include font-size(1);
    line-height: calc(23/16);
    color: $color__black;
    font-weight: 500;
}

@mixin text--lg-strong-inverted {
    @include font-size(1);
    line-height: calc(23/16);
    color: $color__white;
    font-weight: 500;
}

@mixin text--heading-xl {
    @include font-size(1.375);
    line-height: calc(26/22);
    font-family: $font__tempos;
    color: $color__black;
}

@mixin text--heading-l {
    @include font-size(1.375);
    line-height: calc(28/22);
    color: $color__black;
}

@mixin text--heading-m {
    @include font-size(1);
    font-weight: 500;
    line-height: calc(23/16);
    color: $color__black;
}

@mixin text--heading-s {
    @include font-size(1);
    line-height: calc(23/16);
    color: $color__black;
}

@mixin text--heading-s-fancy {
    @include font-size(1);
    line-height: calc(23/16);
    font-family: $font__tempos;
}

@mixin text--heading-s-fancy-inverted {
    @include font-size(1);
    line-height: calc(23/16);
    font-family: $font__tempos;
    color: $color__white;
}

@mixin text--heading-xs {
    @include font-size(0.875);
    line-height: calc(1.64);
    color: $color__black;
}

@mixin text--code {
    @include font-size(0.6875);
    text-transform: uppercase;
    line-height: calc(19/11);
    padding: 0 3px;
    background-color: $color__black--05;
    display: inline-block;
    letter-spacing: 0.03em;
    border-radius: 2px;
    color: $color__black--60;
}

@mixin text--bold {
    font-weight: 500;
}

@mixin text--thin {
    font-weight: 400;
}

@mixin text--strike-through {
    text-decoration: line-through;
}

@mixin text--italic {
    font-style: italic;
}

@mixin text--medium-grey {
    @include font-size (.75);
    font-weight: 500;
    letter-spacing: .2px;
    color: $color__black--60
}

@mixin text--highlighted {
    background-color: $color__highlight-yellow;
}

.text {
    &--normal { @include text--normal; }
    &--muted { @include text--muted; }
    &--normal-inverted { @include text--normal-inverted; }
    &--white { @include text--white; }
    &--blue { @include text--blue; }
    &--strong-blue { @include text--strong-blue; }
    &--dark { @include text--dark; }
    &--dark-link { @include text--dark-link; }
    &--strong { @include text--strong; }
    &--strong-inverted { @include text--strong-inverted; }
    &--link { @include text--link; }
    &--link-grey { @include text--link-grey; }
    &--link-inverted { @include text--link-inverted; }
    &--link-white { @include text--link-white; }
    &--warning { @include text--warning; }
    &--warning-link { @include text--warning-link; }
    &--link-uppercase { @include text--link-uppercase; }
    &--lg { @include text--lg; }
    &--lg-muted { @include text--lg-muted; }
    &--lg-dark { @include text--lg-dark; }
    &--lg-strong { @include text--lg-strong; }
    &--lg-strong-inverted { @include text--lg-strong-inverted; }
    &--heading-xl { @include text--heading-xl; }
    &--heading-l { @include text--heading-l; }
    &--heading-m { @include text--heading-m; }
    &--heading-s { @include text--heading-s; }
    &--heading-s-fancy { @include text--heading-s-fancy; }
    &--heading-s-fancy-inverted { @include text--heading-s-fancy-inverted; }
    &--heading-xs { @include text--heading-xs; }
    &--code { @include text--code; }
    &--uppercase { text-transform: uppercase; }
    &--small { @include text--small }
    &--smaller { @include text--smaller }
    &--bold { @include text--bold }
    &--thin { @include text--thin }
    &--italic { @include text--italic }
    &--strike-through { @include text--strike-through }
    &--medium-grey { @include text--medium-grey }
    &--inline-baseline { display: inline-flex; align-items: baseline; }
    &--inline-center { display: inline-flex; align-items: center; }
    &--highlighted { @include text--highlighted }
}
