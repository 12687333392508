.activity {
    &__list {
        background-color: $color__white;
        border: 1px solid $color__black--10;
        border-radius: 2px;
    }

    &__list-item {
        border-bottom: 1px solid $color__black--10;

        &:last-child {
            border-bottom: 0;
        }
    }

    &__list-item-top {
        padding: 1.25rem;
        display: flex;
        align-items: baseline;
        gap: 1.25rem;

        @include smaller-than-or-eq($break-mobile) {
            flex-wrap: wrap;
            gap: 0;
        }

        .activity__list-item-time {
            @include smaller-than-or-eq($break-mobile) {
                text-align: left;
                margin-left: .5rem;
            }
        }
    }

    &__list-item-type {
        @include text--normal;
        flex: 0;
        flex-basis: 9rem;

        @include smaller-than-or-eq($break-mobile) {
            @include text--strong;
            flex-basis: auto;
            margin-bottom: .5rem;
        }
    }

    &__list-item-content {
        flex: 1;

        @include smaller-than-or-eq($break-mobile) {
            order: 3;
            flex-basis: 100%;
        }
    }

    &__list-item-time {
        @include text--normal;
        flex: 0 1 80px; // min-width of 80px
        white-space: nowrap;
        text-align: right;
        margin-left: auto;
    }

    &__list-item-row {
        display: flex;
        align-items: baseline;
        gap: 1.25rem;
    }

    &__list-item-secondary {
        padding: 0 1.25rem 1.25rem 11.5rem;

        @include smaller-than-or-eq($break-mobile) {
            padding-left: 1.25rem;
        }
    }

    &__list-item-inset {
        border-left: 1px solid $color__black;
        padding: 0 0 .75rem 1rem;

        &:last-of-type {
            padding-bottom: 0;
        }

        &--light {
            border-left-color: $color__black--10;
        }
    }

    &__list-item-bottom-msg {
        & + & {
            margin-top: .25rem;
        }
    }

    &__empty-msg {
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}
