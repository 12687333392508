// Remove leaflet's popup pointer (tip)
.leaflet-popup-tip-container {
    display: none;
}

// Replace base css
.leaflet-popup-content-wrapper {
    border-radius: 3px;
    z-index: 1000;
}

// Replace base css
.leaflet-popup-content {
    width: 310px !important;
    margin: 10px 15px 15px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    font-family: $font__soehne;
    font-size: 14px;
}

.ais-popup {
    position: relative;

    &__close-button {
        width: 9px;
        height: 9px;
        align-self: center;
        background-image: url(/svg/close-button.svg);
    }

    &__icon {
        height: 6px;
        width: 6px;
        align-self: flex-start;
        top: 6px;
        left: -14px;
        position: absolute;
        &--purple {
            background-image: url(/svg/ais-layers-purple.svg);
        }
        &--blue {
            background-image: url(/svg/ais-layers-blue.svg);
        }
        &--green {
            background-image: url(/svg/ais-layers-green.svg);
        }
        &--grey {
            background-image: url(/svg/ais-layers-grey.svg);
        }
        &--orientation {
            height: 12px;
            width: 12px;
            left: -16px;
            top: 4px;
            transform-origin: 6.5px 4.5px;
            background-image: url(/svg/orientation.svg);
        }
    }

    &__copy {
        margin-right: 18px;
    }

    &__date {
        padding: 0;
        margin-bottom: 10px;
    }
}

// Remove in/out transition
.leaflet-fade-anim .leaflet-popup {
    opacity: 1;
    transition: none;
}

// Fix to bottom right of AIS point
.leaflet-popup {
    top: 5px !important;
    left: 5px !important;
}

.leaflet-container a.leaflet-popup-close-button {
    width: 9px;
    height: 9px;
    font-size: 0;
    top: 15px;
    right: 15px;
    background: url(/svg/close-button.svg) transparent;
    color: transparent;
}