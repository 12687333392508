.auth-layout {
    background-image: url(/img/ocean.jpg), linear-gradient(180deg, $color__deepish-blue 0%, $color__deep-blue 42.7%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &__page-wrapper {
        flex: 1 0 auto;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 2rem 1rem;
        max-width: 1200px;
        margin: 0 auto 2rem;
        align-items: center;
        border-bottom: 1px solid $color__black--10;

        &__btn {
            border: 0;
            border-radius: .25rem;
            background: $color__white--10;

            &:hover:not([disabled]), &:focus {
                background: $color__white--20;
            }
        }
    }

    &__footer {
        flex-shrink: 0;
        margin: 2rem auto 0;
        width: 100%;
        max-width: 1200px;
        padding: 2.5rem 1rem;
        color: $color__white;
        border-top: 1px solid $color__white--15;
        display: flex;
        justify-content: space-between;

        @include smaller-than(tablet) {
            display: block;
        }
    }

    &__footer-left {
        display: flex;
        align-items: center;

        @include smaller-than(tablet) {
            display: block;
            margin-bottom: 1rem;
        }
    }

    &__footer-right {
        display: flex;
        align-items: center;
    }

    &__footer-text {
        a {
            color: $color__white;
            text-decoration: underline;

            &:hover {
                color: $color__white;
                text-decoration: none;
            }
        }
    }

    &__footer-link {
        color: $color__white;
        text-decoration: underline;
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $color__white;
            text-decoration: none;
        }
    }

    &__content-wrapper {
        background: $color__white--15;
        padding: 10px;
        border-radius: .5rem;
        margin: 0 auto;
        max-width: 32rem;

        @include smaller-than(mobile) {
            background: none;
            padding: 0 1rem;
        }
    }

    &__content {
        background: $color__white;
        padding: 3rem;
        border-radius: .25rem;

        @include smaller-than(mobile) {
            padding: 1rem;
        }
    }

    &__title {
        @include text--heading-m;
        margin-bottom: 3rem;
    }

    &__underneath {
        color: $color__white;
        text-align: center;
        margin: 1rem auto;
        padding: 0 1rem;

        a {
            color: $color__white;
            text-decoration: underline;

            &:hover {
                color: $color__white;
                text-decoration: none;
            }
        }
    }
}