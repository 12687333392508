// Override react-tooltip default styles
@mixin tooltip-overrides {
    &::after {
        border-top-width: 4px;
        border-left-width: 4px;
        border-right-width: 4px;
        border-bottom-width: 4px;
    }
}

// Need to pull this out because it doesn't work inside the block below because of the `body` tag
.__react_component_tooltip--key + .__react_component_tooltip--key {
    margin-left: -3px;
}

// Use body here to give the classes more specificity than the generated classes
body .__react_component_tooltip {
    background: $color__black--80;
    border-radius: 2px;
    padding: 8px 10px 7px;
    @include font-size(0.8125);
    color: $color__white;

    &--header {
        display: block;
        text-align: center;
        margin-bottom: 5px;
    }

    &--keys {
        display: flex;
        justify-content: center;
    }

    &--text {
        margin-right: 5px;
        color: $color__white--50;
        line-height: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &--key {
        @include font-size(0.8125);
        color: $color__white--50;

        background-color: $color__white--10;
        height: 20px;
        min-width: 20px;
        padding: 0 5px;
        display: inline-block;
        text-align: center;
        border-radius: 2px;
        margin-right: 5px;
        line-height: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &--dark {
        background-color: $color__black;
        z-index: 99;
    }

    &.place-top {
        @include tooltip-overrides;
        margin-top: -6px;

        &::after {
            border-top-color: $color__black--80;
            bottom: -5px;
            margin-left: -4px;
        }
    }

    &.place-left {
        @include tooltip-overrides;
        margin-left: -6px;

        &::after {
            border-left-color: $color__black--80;
            right: -5px;
        }
    }

    &.place-right {
        @include tooltip-overrides;
        margin-left: 6px;

        &::after {
            border-right-color: $color__black--80;
            left: -5px;
        }
    }

    &.place-bottom {
        @include tooltip-overrides;
        margin-top: 6px;

        &::after {
            border-bottom-color: $color__black--80;
            top: -5px;
            margin-left: -4px;
        }
    }
}
