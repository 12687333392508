.measurements {
    &__spread {
        @include text--dark;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .5rem 0;
    }

    &__item {
        border-bottom: 1px solid $color__black--10;
        margin: 0 -1rem;
        padding: 0 1rem 1rem;

        &:last-child {
            border-bottom: 0;
        }

        &--brief {
            padding: .75rem 1rem;
        }

        &--line {
            position: relative;
            overflow: hidden;
            z-index: 0;
            &::before {
                content: '';
                position: absolute;
                height: 230px;
                width: 2px;
                left: 21px;
                top: 50px;
                background: #00D5FF;
                z-index: -1;
            }
        }

        &--area {
            background: transparent url(/svg/area.svg) no-repeat 1rem 53px;
        }
    }

    &__line {
        display: flex;
        padding-left: var(--bullet-left);
        background-repeat: no-repeat;
        background-position: 0 50%;

        &--radius {
            background-image: url(/svg/radius.svg);
            background-position: 0 6px;
        }

        &--circle {
            background-image: url(/svg/centre.svg);
        }

        &--centroid {
            background: transparent url(/svg/measurement-dot.svg) no-repeat 2px 10px;
        }

        &--line {
            background-image: url(/svg/line.svg);
        }

        &--area {
            background-image: url(/svg/area.svg);
        }

        &--selected-point {
            background: transparent url(/svg/measurement-dot.svg) no-repeat 2px 10px;
        }

        &--coords-lg {
            padding-left: 1rem;
            justify-content: space-between;
        }

        &--speedUnit {
            margin-left: .5rem;
            width: 35px;
            padding: 0.25rem 0;
        }

        &--lat-lng {
            padding-left: 0;
            display: flex;
            padding-left: var(--bullet-left);
        }

        &--lng {
            padding-left: 3px;
            width: 50%
        }

        &--bg-top {
            background-position: 0 3px;
        }
    }

    &__label {
        @include text--dark;
        margin-right: auto;
        padding: 0.25rem 0;
        flex: 1;

        &--right {
            margin-right: 0;
            text-align: right
        }

        &--internal {
            width: 30px;
        }
    }

    &__text-input {
        @include text--dark;
        font-family: $font__soehne;
        border: 1px solid $color__black--10;
        border-radius: 2px;
        display: inline-block;
        padding: 4px .25rem 6px;
        margin-left: .5rem;
        outline: none;

        // Get rid of number arrow controls (https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp)
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &--error,  &--error:focus {
            border-color: $color__alert;
        }

        &--line {
            width: 100px;
            text-align: right;
        }

        &--distance {
            height: 30px;
            width: 80px;
            text-align: right;
        }

        &--speed {
            height: 30px;
            width: 80px;
            text-align: right;
        }

        &--coord {
            width: 60px;
        }

        & > input {
            @include remove-os-appearance;
            @include text--dark;
            font-family: $font__soehne;
            display: inline-block;
            border: 0;
            padding: 0;
            outline: none;

            // Get rid of number arrow controls (https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp)
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    &__line-dot {
        background: transparent url(/svg/measurement-dot.svg) no-repeat 2px 6px;
    }

    &__line-arrow {
        position: relative;
        // Clips green line
        &::before {
            content: '';
            position: absolute;
            height: 200px;
            width: 2px;
            left: 5px;
            top: 16px;
            background: white;
        }
        // Arrow
        &::after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 8px;
            background: transparent url(/svg/measurement-arrow.svg) no-repeat 1px 8px;
        }
    }

    &__line-icon {
        width: 20px;
        display: flex;
        justify-content: space-around;
    }

    &__line--dms {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 0.25rem;

        & > label:first-child {
            border-radius: 2px 2px 0 0;
        }
        & > label:last-child {
            border-radius: 0 0 2px 2px;
            margin-top: -1px;
        }
    }

    &__line--dd {
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-bottom: 0.25rem;

        & > label:first-child {
            border-radius: 2px 0 0 2px;
        }
        & > label:last-child {
            border-radius: 0 2px 2px 0;
            margin-left: -1px;
        }
    }

    &__text-input-outer {
        @include text--dark;
        border: 1px solid #d1d1d1;
        display: flex;
        padding: 4px .5rem 6px;
        width: 100%;

        &--error, &--error:focus {
            border-color: $color__alert;
            z-index: 1;
        }

        input {
            @include remove-os-appearance;
            @include text--dark;
            font-family: $font__soehne;
            display: inline-block;
            border: 0;
            padding: 0;
            outline: none;

            // Get rid of number arrow controls (https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp)
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    &__select {
        border: 1px solid $color__black--10;
        border-radius: 2px;
        display: inline-block;
        margin-left: .25rem;

        select {
            @include text--dark;
            @include remove-os-appearance;
            font-family: $font__soehne;
            text-align-last: right;
            background: transparent;
            border: 0;
            padding: 4px 1.25rem 6px .25rem;
            background: url(/svg/down-arrow.svg) right .5rem top 13px no-repeat;
            width: 50px;
        }

        &--kmph {
            select {
                width: auto;
            }
        }
    }

    &__unit {
        border: none;
        font-family: $font__soehne;
        @include text--dark;
        padding: 4px 1.25rem 6px .25rem;
        text-align-last: right;
        width: 29px;
    }

    &__delete {
        @include text--warning-link;
        margin-top: .5rem;
    }

    &__dropdown-btn {
        @include text--link;
        white-space: nowrap;
        background-repeat: no-repeat;
        background-position: 1rem 50%;
        padding: .7rem 1.1rem;
        border-bottom: 1px solid $color__black--10;
        display: block;
        width: 100%;

        &--circle {
            background-image: url(/svg/centre.svg);
        }

        &--line {
            background-image: url(/svg/line.svg);
        }

        &--area {
            background-image: url(/svg/area.svg);

        }

        &:last-child {
            border-bottom: 0;
        }
    }
}
