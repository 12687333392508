.menu {
    padding: 0 1rem .75rem;

    &__slideout {
        display: none;

        @include smaller-than-or-eq(mobile) {
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: var(--slideout-width);
            background-color: $color__deep-blue;
            margin-top: 0;
            padding: 0;
            height: 100%;
            z-index: 2;
        }
    }

    &__slideout-inner {
        position: fixed;
        top: 0;
        width: var(--slideout-width);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $color__white;
        min-height: 100vw;
        min-height: -webkit-fill-available;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: .75rem 0;
    }

    &__title {
        @include text--lg-strong-inverted;
    }

    &__header-extra {
        @include text--normal-inverted;
    }

    &__list {
        margin: 0 -1rem;
        padding: 0 1rem .25rem 1rem;
    }

    &__mobile-sublist {
        display: none;

        @include smaller-than(mobile) {
            .menu__list-link--active + & {
                display: block;
            }
        }
    }

    &__list-item {
        margin-top: .75rem;

        &:first-child {
            margin-top: 0;
        }

        &--border-top {
            border-top: 1px solid $color__white--10;
            padding-top: .5rem;
        }
    }

    &__list-link {
        @include text--link-inverted;
        display: block;
        margin: .25rem -1rem 0;
        padding: 0 .75rem;
        border-left: 4px solid transparent;

        &--active {
            color: $color__white;
            border-left-color: $color__white;
        }

        &--no-active-mobile {
            @include smaller-than(mobile) {
                border-left-color: transparent;
            }
        }
    }
}