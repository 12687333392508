.popup {
    max-width: 20rem;
    width: auto;
    display: flex;
    flex-direction: column;
    z-index: 3;
    background-color: $color__white;
    box-shadow: 0 0 10px $color__black--25;
    padding: 0.5rem var(--padding) var(--padding);
    border-radius: 3px;

    &--small {
        width: 250px;
    }

    &--big {
        width: 20rem;
    }

    &--scrollable {
        margin-bottom: -0.5rem;
    }
}
