.logo {
    display: flex;
    align-items: center;

    &__img {
        display: block;
        margin-right: .7rem;
    }

    &__text {
        // 15px
        @include font-size(.9375);
        color: $color__white;
        line-height: 1;
        font-family: $font__tempos;
        width: 90px;  
    }
}