.pill {
    @include font-size(.75);
    gap: 2px;
    display: inline-flex;
    align-items: baseline;
    color: $color__blue;
    background-color: $color__blue--20;
    padding: 3px 5px;
    border-radius: 2px;
    font-weight: 500;
    line-height: var(--standard-line-height);
    max-width: 100%;
    position: relative;
    border: 1px solid rgba($color__black--60, 0.1);

    &--large {
        @include font-size(.875);
    }

    &--interactive {
        cursor: pointer;
    }

    &--disabled {
        opacity: .75;
        cursor: auto;
    }

    &--error {
        border-color: $color__alert;
    }

    &--strike-through:after, &--deleted:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 50%;
        height: 1px;
        border-bottom: 1px solid currentColor;
        width: calc(100% - 8px);
    }

    &--deleted {
        opacity: 0.6;
    }

    &--dim {
        opacity: 0.4;
    }

    button {
        color: currentColor;
    }

    &__delete-btn {
        align-self: stretch;
        padding-left: 5px;
        margin-left: 3px; // 2px gap + 3px margin = 5px spacing left
        border-left: 1px solid rgba($color__blue, 0.1);

        .pill--GLOBAL & {
            border-color: rgba($color__vessel-red--selected, 0.1);
        }
    }

    &__edit-text {
        min-width: 1px;

        &:focus {
            outline: none;
        }
    }

    &__manage-line {
        border-bottom: 1px solid $color__black--10;

        &:last-child {
            border-bottom: 0;
        }
    }

    &__popup-container {
        position: relative;
        display: inline-flex;
    }

    &__suffix {
        align-self: stretch;
        padding-left: 5px;
        margin-left: 3px; // 2px gap + 3px margin = 5px spacing left
        border-left: 1px solid rgba($color__blue, 0.1);

        .pill--GLOBAL & {
            border-color: rgba($color__vessel-red--selected, 0.1);
        }

        .pill--RISK_INDICATOR & {
            border-color: rgba($color__black--60, 0.1)
        }
    }

    &--GLOBAL { // uppercase comes from db
        color: $color__vessel-red--selected;
        background-color: $color__vessel-red--selected--10;
    }

    &--ALERT {
        background-color: $color__det-match;
    }

    &--REGISTER {
        color: #426C2B;
        background-color: #E3F5D8;

        &:hover {
            color: #426C2B
        }
    }

    &--RISK_INDICATOR {
        color: $color__black--60;
        background-color: rgba(0, 0, 0, 0.07);
    }
}

.pills {
    display: inline-flex;
    flex-flow: wrap;
    gap: .25rem;
    align-items: baseline;

    &--per-line {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &--inline {
        display: inline;
        line-height: 1.85;

        & > .pill {
            margin-right: .25rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
