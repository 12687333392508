.user-nav {
    padding: 1rem;

    &__name {
        @include text--strong-inverted;
    }

    &__org {
        @include text--normal-inverted;
        margin-top: .25rem;
    }

    &__links {
        border-top: 1px solid $color__white--10;
        margin-top: .75rem;
    }

    &__link {
        @include text--link-inverted;
        display: block;
        margin-top: .75rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__select {
        @include text--link-inverted;
        @include icon-inherits-color;
        position: relative;

        select {
            @include remove-os-appearance;
            background-color: transparent;
            color: transparent;
            border: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            &::-ms-expand {
                display: none;
            }

            option {
                color: $color__text-grey;
            }
        }

        .icon {
            margin-left: .25rem;
        }
    }
}