.ifc-incidents {

    &__marker {
        height: 15px;
        width: 15px;
        position: relative;
        top: 2px;
    }

    &__dropdown {
        height: 15px;
        width: 15px;
        position: absolute;
        right: -14px;
    }
}