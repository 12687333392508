.search {
    position: absolute;
    top: var(--space);
    right: var(--space);
    width: var(--details-width);
    pointer-events: auto;
    background: $color__white;
    border-radius: 2px;
    padding: .25rem .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 45%;

    @include smaller-than-or-eq(mobile) {
        max-width: 100%;
    }

    &__wrapper {
        @include smaller-than-or-eq(mobile) {
            display: none;

            &--mobile-on {
                display: block;
            }
        }

        @media print {
            display: none;
        }
    }

    &__input {
        @include text--dark;
        line-height: 1;
        height: 26px;
        border: 1px solid transparent;
        border-radius: 2px;
        padding: 3px .5rem 5px;
        width: 240px;

        &:focus {
            outline: none;
        }

        &--error {
            border: 1px solid $color__alert;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
    }

    &__results {
        position: absolute;
        top: 2.75rem;
        right: var(--space);
        width: var(--details-width);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 129px);
        max-width: 45%;

        @include smaller-than-or-eq(mobile) {
            max-width: 100%;
        }
    }

    &__result {
        padding: 10px 0 !important;
    }

    &__result__name {
        color: $color__black
    }

    &__result__addto {
        margin-left: 12px;
    }

}