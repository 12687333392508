.settings {
    padding-bottom: 2rem;

    @include smaller-than-or-eq(mobile) {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        width: 100vw;
        position: relative;
        transition: transform .5s;
        transition-timing-function: ease;
        padding-bottom: 0;

        &--open {
            transform: translateX(-272px);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1rem 0;
    }

    &__hamburger {
        @include icon-inherits-color;
        display: none;
        height: 16px;
        width: 16px;
        color: $color__blue;

        &:hover {
            color: $color__dark-blue;
        }

        @include smaller-than-or-eq(mobile) {
            display: block;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        height: 6rem;
        margin: 0 2rem;
        border-bottom: 1px solid $color__black--10;

        @include smaller-than-or-eq(mobile) {
            display: none;
        }
    }

    &__mobile-title {
        display: none;
        align-items: center;
        margin: 0 1.5rem 1.5rem;
        height: 4rem;
        border-bottom: 1px solid $color__white--40;

        @include smaller-than-or-eq(mobile) {
            display: flex;
        }
    }

    &__back {
        @include font-size(0.875);
        @include icon-inherits-color;
        line-height: 1.29;
        color: $color__blue;

        &:hover {
            color: $color__dark-blue;
        }

        &:focus {
            outline: none;
            color: $color__darker-blue;
        }

        .icon {
            margin-right: .25rem;
        }
    }

    &__body {
        flex: 1;
        display: flex;

        @include smaller-than-or-eq(mobile) {
            display: block;
        }
    }

    &__nav {
        flex: 1;
        margin-top: 3rem;
        min-width: 12rem;

        @include smaller-than-or-eq(mobile) {
            position: absolute;
            left: 100%;
            top: 0;
            height: 100vh;
            width: 272px;
            background-color: $color__blue;
            color: $color__white;
            margin-top: 0;
        }

        &__item {
            @include font-size(0.875);
            display: block;
            min-height: 1.5rem;
            border-left: 4px solid $color__white;
            padding-left: 2rem;
            line-height: 1.29;
            color: $color__blue;
            margin-bottom: 1rem;

            &:focus {
                outline: none;
                color: $color__darker-green;
            }

            @include smaller-than-or-eq(mobile) {
                @include font-size(1);
                line-height: calc(23/16);
                border-color: $color__blue;
                padding-left: 1.5rem;
                color: $color__white;
            }

            &--current {
                border-color: $color__black;
                color: $color__black;

                @include smaller-than-or-eq(mobile) {
                    color: $color__white;
                }
            }
        }
    }

    &__main {
        margin-top: 3rem;
        max-width: 40rem;
        width: 100%;
        padding: 0 2rem 2rem;

        &--account {
            margin: 2rem auto 0;
        }
    }

    &__sectionheader {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: .25rem;
    }

    &__right {
        flex: 1;
    }
}

.pagination {
    &__link {
        @include font-size(0.875);
        margin-left: 1rem;
    }
}
