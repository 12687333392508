.orglist {
    &__item {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        padding: 1rem 0;

        &:not(:has(+ .orglist__item--group)) {
            padding-bottom: 1rem;
            border-bottom: 1px solid $color__black--10;
        }

        &:last-child {
            border-bottom: 0;
        }

        &--group {
            padding: 0;

            @include smaller-than-or-eq(mobile) {
                margin-bottom: 1rem;
            }
        }

        @include smaller-than-or-eq(mobile) {
            flex-wrap: wrap;
        }
    }

    &__field {
        order: 1;
        flex: 1 1 1rem;
        padding-right: 1rem;

        &--wide {
            flex: 1.5 1 2rem;
        }

        &:first-child {
            .table__sort:before {
                right: -14px;
                left: auto;
            }
        }

        .orglist__item--group &:first-child {
            border-left: 1px solid $color__black--10;
            padding: .25rem 1rem;
        }

        @include smaller-than-or-eq(mobile) {
            order: 4;
            flex: auto;
            margin-top: .5rem;
            width: 100%;
            text-align: left;

            .orglist__item--group & {
                padding: .25rem 1rem;
                margin-top: 0;

                &:first-child {
                    border-left: none;
                    border-top: 1px solid $color__black--10;
                    padding-top: 1rem;
                }
            }
        }
    }

    &__actions {
        order: 2;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        //padding-left: 2rem;

        @include smaller-than-or-eq(mobile) {
            justify-content: flex-start;
            margin-left: 0;
        }
    }

    &__action {
        &:last-child {
            padding-left: 1.5rem;
        }
    }
}
